// Add flourish
.homeIntro p {
  @include heading-underline($colour: $brand-primary,
    $justify: left,
  );
}

// Add flourish
.homeImpactWrapper>h2,
.feedsTitle {
  @include heading-underline($colour: $brand-primary,
  );
}

// Adjust spacing
.homeIntroWrapper {
  padding-bottom: $spacer * 3;
}

// Change colour of second feed
.homeFeedBox2 {
  background-color: $_logogrey;

  .feedsTitle {
    color: white;
  }
}

// Remove underline on links in footer
.pageFooter a:not(.button) {
  text-decoration: none;
}

// Footer stuff
.footerBox .socialIcons {
  margin-left: -$spacer / 2;
  margin-bottom: $spacer;
}

.footerBox .button {
  background-color: $brand-primary;
  color: $_deepgreen;
}

.funderLogos {
  margin-top: $spacer;

  img {
    height: auto;
    width: 150px;
    margin: $spacer $spacer * 2 $spacer 0;

    &:last-child {
      width: 150px;
    }
  }
}

.footerBox.footerBox4 {
  grid-column: 1 / span 16;
  background-color: white;
  padding: $spacer 0;
  margin-left: 0;
  margin-right: 0;

  img {
    height: auto;
    width: 100px;
    margin: $spacer $spacer * 2 $spacer 0;

    &.largerlogo {
      width: 140px;
    }
  }

  p {
    margin: $spacer 0 0 0;
    font-size: 0.875em;
    color: $grey-light;
    text-align: left;
    max-width: $container-max-width;
    margin-left: auto;
    margin-right: auto;
    padding-left: $spacer;
    padding-right: $spacer;
  }

  p:last-child {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media (max-width: map-get($breakpoints, xl)) {
      flex-wrap: wrap;
    }
  }

  p img:last-child {
    width: 60px;
  }
}

.companyNumbers {
  font-size: $font-size-small;
}

// Remove bottom padding
.pageFooter {
  padding-bottom: 0;
}

// Fix gap on mobile
.pageHeader .headerContent {
  @media (max-width: map-get($breakpoints, sm)) {
    margin-bottom: 0;
  }
}

// Tagline
.tagline {
  strong {
    color: $_logogrey;
  }
}

// QA

@media (max-width: map-get($breakpoints, sm)) {
  .homeFeatures .homeFeature:first-of-type {
    margin-top: 0;
  }
}

// Video fix DES-4350
.embed-container.videoPopUpWrapper {
  height: 100%;
  max-height: 530px;
}

// DES-4982 Youtube mobile aspect ratio fix
.videoWrapper, .postVideoObjectEmbed {
  position: relative;
  padding-bottom: 56.25%;  // 16:9
  height: 0;
  border-radius: $border-radius;
  overflow: hidden;
}

.postVideoObjectEmbed iframe,
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


// Subsite overall - Header
body.subsite {

  // Show stuff
  .adminBar, .adminBarEdit {
    z-index: 1;
  }

  // Hide stuff
  .menuMain, .menuAdminContainer, .menuSub, .menuMainAlt, .feedsTitle, .mainCallToAction {
    display: none;
  }

  // Homepage item order
  .contentContainer .headerWrapper.headerWrapperSubsite {
    order: -1;
  }

  // Feed - Layout
  .homeFeed {
    padding-top: $spacer;
  }

  .post .contentBlockWrapper,
  .post .headerText .title {
    max-width: $container-max-width;
    .postContent {
      margin-left: 0;
    }
  }

  // Quick giving panel over banner
  .quickGivingPanel {
    position: relative;
    border-radius: 4px;
    padding: $spacer * 1.5;
    background-color: $_deepgreen;

    .formQuestion.donationAmount .donationAmountFigure { padding: 0.5rem; }

    .selectDonationAmount { margin-bottom: 0; }
    .formQuestion.donationAmount {
      background-color: $white;
      outline: none;
      border-radius: 4px;
    }
    .formQuestion.donationAmount.donationSelected { box-shadow: inset 0px 0px 0px 3px $_yellow; }
    .button {
      border-radius: 4px;
      background-color: $_yellow;
      color: text-contrast($_yellow);
    }
    .quickGivingIntro {
      padding: 0 !important;
      border: none !important;
      // max-width: 600px;
      margin: 0 auto $spacer;
      font-family: $headings-font-family;
      font-weight: bold;
      font-size: $font-size-h4;
      color: text-contrast($_deepgreen);
    }
  }

  // Carousel play button
  .carouselSubsite .carouselSlideTitle.showsVideo {
    pointer-events: all;
    &:before {
      left: 50%;
      top: 50%;
      width: 80px;
      border-radius: 80px;
      background-color: $white;
      border: 3px solid $_deepgreen;
      color: $_yellow;
      font-size: 30px;
      width: 80px !important;
      border-radius: 80px;
      transform: translate(-50%, -50%);
      height: 80px !important;
      line-height: 77px !important;
      right: auto !important;
    }

    &:hover {
      img { opacity: 1; }
      &:before {
        background-color: $_deepgreen;
        color: $white;
        transform: translate(-50%, -50%) scale(1.1);
      }
    }
  }

  &.homepage .headerTextSubsite {
    padding-top: $spacer;
    margin-bottom: #{$spacer /2 };
  }

  &.homepage .quickGivingPanel {
    max-width: 585px;
  }

  &:not(.homepage) .post {
    overflow-x: hidden;
    img { border-radius: 4px; }
  }
  &:not(.homepage) .quickGivingPanel {
    margin: #{$spacer * 2} 0;
    &:before {
      content: '';
      position: absolute;
      width: 120vw;
      height: 100%;
      background-color: $_deepgreen;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
    }
  }

  &:not(.homepage) .headerTextSubsite {
    display: none;
  }

  @media (min-width: map-get($breakpoints, lg) + 1px) {

    // Header transparnt + absolute
    &.page-has-banner .pageHeader {
      position: absolute;
      top: 0;
      background: linear-gradient(to bottom, rgba($black, 0.75), rgba($black, 0));
    }

    &.homepage .headerTextSubsite { display: none; }

    // Subsite title
    &.homepage .headerWrapperSubsite {
      position: relative;
      .subsiteTitle {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        max-width: $container-max-width;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: $white;
        padding: 0 $site-bleed;
        font-size: 3.25em;
      } 
    }
    
    // Quick giving panel - over banner
    &.page-has-banner .subsiteBody {
      position: relative;
      padding-top: $spacer * 7;
      .quickGivingPanel {
        position: absolute;
        width: auto;
        top: 0;
        right: 15px;
        transform: translateY(-65%);
      }
    }
  }

  @media (max-width: map-get($breakpoints, lg)) {
    .headerWrapperSubsite .subsiteTitle {
      display: none;
    }
    .headerTextSubsite {
      display: block;
    }
  }

  @media (max-width: map-get($breakpoints, sm)) {
    .pageHeader .headerContent {
      margin-top: $spacer * 0.5;
      margin-bottom: $spacer * 0.5;
      padding-bottom: 0;
    }
  }

  
}

@media  (min-width: #{$nav-breakpoint + 1px}) {
  .menuMain .mainCallToAction {
    display: none;
  }
}

// DES-5381
.PostSystemCategory_hide-sidebar {
  // .breadcrumbWrapper,
  // .post .contentBlockWrapper,
  // .post .headerText .title {
  //   max-width: calc(#{$post-content-width} - calc(#{$sidebar-adjacent-width} + #{$sidebar-adjacent-gap}));
  // }  
  .postAside {
    display: none;
  }
}

// Tag design DES-5442
.associatedTagCloud {
  div {
    display: flex;
    flex-wrap: wrap;
  }
  a {
    padding: 1px 5px;
    border-radius: 3px;
    background-color: $grey-lighter;
    color: $body-colour;
    margin: 2px;
    transition: background-color 50ms ease-in-out;
    &:hover {
      background-color: darken($grey-lighter, 10%);
      text-decoration: none;
    }
  }
}

// Alt donate form styling

//body.donate { // Target all donation forms
[class*="donate-donate"] { // Target just the donation forms of the appeal with url 'donate'
  .formDonatePage {
    .carousel {
      display: none;
    }
    background-attachment: fixed;
    background-size: cover;
    background-position: left top;
    .donationForm, .headerText {
      max-width: $container-max-width;
      margin: auto;
      padding: 0 20px;
    }
    .headerText {
      padding-top: 3rem;
    }
    .donationFormItems {
      @media (min-width: 1024px) {
        max-width: 750px;
      }
      padding-bottom: 3rem;
    }
    .post .headerText h1.title {
      @media (min-width: 1024px) {
        max-width: 750px;
      }
      background-color: white;
      margin: 0;
      padding: 40px 20px 20px;
      text-align: center;
    }
    .listingIntro {
      @media (min-width: 1024px) {
      max-width: 750px;
      }
      background-color: white;
      margin: 0;
      text-align: center;
    }
    .donationFormItems .contentBlockWrapper {
      background-color: white;
      padding: 15px;
      margin-bottom: 0;
    }
  }
}

// Hide background banner image generated by JS on all donation forms other than those with url 'donate' 
body.donate:not([class*="donate-donate"]) { // Target all donation forms
  .formDonatePage {
    background-image: none!important;
  }
}