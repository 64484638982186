//
// Config values
//

$platform-name: 'arthritisireland';


//
// Colours
//

$_deepgreen: #385057;
$_yellow: #fbba00;
$_orange: #ef7d00;
$_skyblue: #1ea1dc;
$_turquoise: #19969f;
$_leafgreen: #95bb20;
$_burntorange: #c42f18;
$_logogrey: #63888c;
$_logolightgrey: #929a90;

$brand-primary: $_yellow;
$brand-secondary: $_orange;

$donate-colour: $brand-secondary;

// Create greyscale
$black:             #000 !default;
$grey-dark:         #292b2c !default;
$grey:              #464a4c !default;
$grey-light:        #636c72 !default;
$grey-lighter:      #eceeef !default;
$grey-lightest:     #f7f7f9 !default;
$white:             #fff !default;


//
// Layout
//

$container-max-width: 1230px;
$post-content-width: 1180px;


// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1080px,
  xl: 1200px
) !default;


// Spacers
$spacer: 1rem !default;


//
// Misc
//

$date-ordinals-enabled: false; // Turn off 'st', 'nd', 'rd' from dates
$body-colour: $_deepgreen;

//
// Transitions
//



//
// Logo
//
$logo: "logo.png";
$logo-width: 230px;
$logo-height: 110px;


//
// Typography
//

$font-family-base: caecilia, sans-serif;
$headings-font-family: museo-sans-rounded, serif;

$font-weight-normal: normal !default;
$font-weight-bold: 800;
$headings-font-weight: 900;

$font-size-h1: 2.5em !default;
$font-size-h2: 2em !default;
$font-size-h3: 1.75em !default;
$font-size-h4: 1.5em !default;
$font-size-h5: 1.25em !default;
$font-size-h6: 1em !default;

$headings-margin-top: 1.5em !default; // This will only affect headings within post content


// Links
$link-colour: $_logogrey;

// Icon font


//
// Buttons
//

$btn-padding-x: 1.5em !default;
$btn-padding-y: .6em !default;
$btn-font-weight: 900;
$btn-font-family: $headings-font-family;
$btn-background-colour: $_deepgreen;
$btn-text-colour: white;

// Donate button
$donate-btn-colour: white;

//
// Social icons
// Documentation: https://fresco-docs.netlify.com/#/header/social-icons/
//
$social-icons-gap: 0;

// Social network colours - use these by changing $social-icons-use-brand-colours to true
$social-icons-background-colour: transparent; // A colour, transparent, or 'brand'
$social-icons-colour: $_deepgreen;
$social-icons-hover-colour: brand; // A colour, or 'brand'

// Header specific
$social-icons-header-colour: $_deepgreen; // A colour, or 'brand'

// Footer specific social icons
$social-icons-footer-colour: $brand-primary;

//
// Share this page
//



//
// Form elements
//

$input-padding-y: .375rem !default;
$input-padding-x: .75rem !default;


// Field groups


//
// Tables
//



//
// Card
//

$card-border: 0;
$card-footer-background-colour: transparent;
$card-heading-font-size: $font-size-h4;
$card-heading-colour: $_deepgreen;

// Card text overlay
$card-text-overlay-breakpoint: 0;

// Card text over

// Card side by side

// Card hover state
$card-hover-image-opacity: 1;
$card-hover-image-scale: 1.2;

// Event cards
$event-card-floating-date: true;

//
// Menu admin
//
$menu-admin-link-colour: $_deepgreen;
$menu-admin-background-colour: rgba($_logolightgrey, 0.1);


//
// Header
//


// Header content

// Tagline
$tagline-enabled: true;
$tagline-above-cta-breakpoint: 4000px;
$tagline-breakpoint: 0;
$tagline-font-family: $font-family-base;

// Main call to action

// Search

// Search - input
$header-search-input-margin-right: -43px;
$header-search-input-padding: $input-padding-y 43px $input-padding-y $input-padding-x;

// Search - button
$header-search-button-background-colour: transparent;
$header-search-button-icon-colour: $grey;

// Social icons

// Colours set above (search for '$social-icons-header')


//
// Sticky header
//


//
// Navigation
//
$nav-background-colour: $_logogrey;


// Top level items
$nav-top-level-item-font-weight: $font-weight-bold;
$nav-top-level-item-colour: white;
$nav-top-level-item-hover-colour: $_deepgreen;
$nav-top-level-item-hover-background-colour: $brand-primary;

// Submenus
$nav-submenu-background-colour: $brand-primary;
$nav-submenu-box-shadow: 0 20px 20px rgba(black, 0.05);
$nav-submenu-item-colour: $_deepgreen;

// Burger button
$burger-btn-bar-breakpoint: map-get($breakpoints, sm); // What viewport width do you want the burger button to pop under the header full width?

// Nav normal
$nav-normal-max-width: 100%; // $container-max-width
$nav-normal-margin-bottom: 0;


//
// Specifically when no banner present and you have gone for $header-absolute: true
//



//
// Carousel
//
$carousel-max-width: 100%;
$carousel-details-background-colour: transparent;
$carousel-contents-max-width: $container-max-width; // How wide do you want .carouselDetailWrapper?
$carousel-heading-colour: white;
$carousel-summary-colour: white;
$carousel-details-margin-y: $spacer * 2;
$carousel-image-overlay: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 20%, rgba(0, 0, 0, 0.19) 45%, rgba(0, 0, 0, 0) 58.99%); // Add a colour value or gradient here
$carousel-read-more-background-colour: $brand-primary;
$carousel-read-more-colour: $_deepgreen;


// Carousel controls (left-right buttons)
$carousel-controls-button-background-colour: transparent;
$carousel-controls-position-y: center; // top, center, bottom
$carousel-controls-position-x: split; // split, left or right
$carousel-controls-margin-x: $spacer;
$carousel-controls-icon-font-size: 1.5em;

// Carousel buttons (dots or tabs)
$carousel-buttons-enabled: false;

// Carousel buttons dots

// Carousel buttons tabs

// Carousel video play button (before breakpoint, when carousel details present)

// Carousel scroll button

// Carousel after breakpoint

// Banner min-height under sm breakpoint - helps to keep some vertical height at narrow viewports, especially for letter-box aspect ratios

//
// Home intro
//

$home-intro-background-colour: $_logolightgrey;
$home-intro-margin-top: 0;
$home-intro-text-align: left;
$home-intro-font-size: $font-size-h1;
$home-intro-colour: white;


//
// Home features
//
$home-features-background-colour: $_logolightgrey;
//$home-features-padding-top: 0;
$home-features-cards-entry-animation-prefab: 1; // See https://fresco-docs.netlify.com/#/cards/entry-animations



//
// Impact stats
//
// $impact-stats-background-colour: $_turquoise;
$impact-stats-background-colour: $_deepgreen;
$impact-stats-background-image-opacity: 0.35;
$impact-stats-background-image: 'stats.jpg';
$impact-stats-heading-text-align: left; // left, center, right


// Heading

// Individual stat

// Figure

// Summary


//
// Home feeds
//
$home-feeds-cards-entry-animation-prefab: 1; // See https://fresco-docs.netlify.com/#/cards/entry-animations


// Feeds title

// Feed item

// Event feed items
$feed-item-event-location-enabled: false;

// Twitter feed


//
// Footer
//

$footer-background-colour: $grey-dark;
$footer-boxes-count: 4;
$footer-prefab: 4;
$footer-link-colour: $brand-primary;

// Footer admin links

// Newsletter
$newsletter-background-colour: transparent;
$newsletter-colour: white;
$newsletter-heading-colour: white;
$newsletter-heading-text-align: left; // left, center, right
$newsletter-use-placeholders: true; // Turn on to use placeholders in the inputs rather than labels
$newsletter-button-background-colour: $brand-primary;

// Newsletter hero - full width form above rest of footer


//
// Context container
//


// Donors list


//
// Posts
//

$post-content-max-width: $post-content-width;


//
// Header text (container for breadcrumb and page title)
//

$header-text-margin-top: $spacer;


//
// Page title
//

$page-title-margin-top: $spacer * 2;


//
// Sidebar
//

$sidebar-enabled: true;

// Associated lists
$associated-latest-enabled: true;
$associated-related-enabled: true !default;
$associated-most-read-enabled: true;
$associated-tag-cloud-enabled: false;

//
// Listing
//


// Listed post settings


//
// Breadcrumb
//



//
// Blockquote
//



//
// Fundraising
//


// Find a fundraiser widget

// Top fundraisers


//
// Donation form
//
$donation-form-frequency-tabs: true;

// Donation amounts


//
// Quick giving panel
//
$quick-giving-background-colour: $donate-colour;
$quick-giving-donation-amount-background-colour: $brand-primary;



//
// Home quick giving panel
//
$home-quick-giving-donation-amount-background-colour: $brand-primary;
$home-quick-giving-donate-btn-background-colour: $donate-colour;
$home-quick-giving-type: overlay; // simple, adjacent or overlay
$home-quick-giving-type-overlay-items-side: right; // If type is overlay, which side do you want the copy and donation items?
$home-quick-giving-type-overlay-gradient: linear-gradient(
  to right,
  rgba(0, 0, 0, 0) 40%,
  rgba(0, 0, 0, 0.75) 100%
) !default;
$home-quick-giving-heading-colour: white;


// Adjacent homepage quick giving

// Overlay homepage quick giving


//
// Totalisers
//



//
// Comments
//



//
// Blog details
//



//
// FAQs
//



//
// Shop
//

$shop-enabled: false; // Quickly disable shop on the platform (hide basket and not include relevant Sass for compilation)

// Departments list

// Product-price

// Product post


//
// Subsite
//
$subsite-enabled: true;
$subsite-hide-main-header: false;
$subsite-hide-main-tagline: true;
$subsite-hide-header-search: true;
$subsite-hide-main-cta: true;
// $subsite-content-max-width: $container-max-width !default;
// $subsite-title-font-size: $page-title-font-size !default;

// Subsite logo
$subsite-logo: title;
// $subsite-logo-retina: $subsite-logo !default;
// $subsite-logo-width: 120px !default;
// $subsite-logo-height: 120px !default;
// $subsite-logo-margin: $header-content-margin-top 0 0 !default;
// Subsite nav


//
// Cookie consent
//
